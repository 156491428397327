// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\templates\\service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-pages-404-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beliefs-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\beliefs\\index.js" /* webpackChunkName: "component---src-pages-beliefs-index-js" */),
  "component---src-pages-breed-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\breed\\index.js" /* webpackChunkName: "component---src-pages-breed-index-js" */),
  "component---src-pages-contact-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\contact\\index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-difference-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\difference\\index.js" /* webpackChunkName: "component---src-pages-difference-index-js" */),
  "component---src-pages-genetics-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\genetics\\index.js" /* webpackChunkName: "component---src-pages-genetics-index-js" */),
  "component---src-pages-history-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\history\\index.js" /* webpackChunkName: "component---src-pages-history-index-js" */),
  "component---src-pages-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\media\\index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-people-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\people\\index.js" /* webpackChunkName: "component---src-pages-people-index-js" */),
  "component---src-pages-people-people-1-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\people\\people-1.js" /* webpackChunkName: "component---src-pages-people-people-1-js" */),
  "component---src-pages-people-people-2-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\people\\people-2.js" /* webpackChunkName: "component---src-pages-people-people-2-js" */),
  "component---src-pages-people-people-3-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\people\\people-3.js" /* webpackChunkName: "component---src-pages-people-people-3-js" */),
  "component---src-pages-people-people-4-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\people\\people-4.js" /* webpackChunkName: "component---src-pages-people-people-4-js" */),
  "component---src-pages-people-people-5-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\people\\people-5.js" /* webpackChunkName: "component---src-pages-people-people-5-js" */),
  "component---src-pages-people-people-6-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\people\\people-6.js" /* webpackChunkName: "component---src-pages-people-people-6-js" */),
  "component---src-pages-producers-index-js": () => import("E:\\Projects\\SPKL\\Phase 2\\Code\\src\\pages\\producers\\index.js" /* webpackChunkName: "component---src-pages-producers-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "E:\\Projects\\SPKL\\Phase 2\\Code\\.cache\\data.json")

